import React from 'react';

import { PageProps, Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import './index.scss'
import ShopifyLogo from "../../../components/images/shopify"
import ImageFooted from '../../../components/widgets/imageFooted';
import { IMG_Shopify } from '../../../components/images/docs/shopify';
import SectionNavigate from '../../../components/widgets/sectionNavigate';

type DataProps = {
    site: {
        buildTime: string
    }
}

const ShopifyPostFacturacion: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
    <Layout isEcommerce={true} location={location}>
        <SEO title="FiscalPOP para eCommerce" />
        <div id='ShopifyDocs' className="_DOCS">
            <div className="integrationLogo">
                <ShopifyLogo />
            </div>
            <h2>
                Manejo de tus facturas
            </h2>
            <p>
                La sección de <b>"Mis facturas"</b> contiene todas aquellas emitidas, agrupadas por el mes en que fueron emitidas.  
            </p>
            <p>
                Existen las series son predeterminados según el tipo de factura y su origen. El folio suele ser el nombre del pedido facturado
            </p>
            <ul>
                <li>
                    <p><b>SNG:</b> Factura generada manualmente desde tu Admin de Shopify (SNG de Single)</p>
                </li>
                <li>
                    <p><b>BLK:</b> Factura múltiple generada desde la sección de orders de Shopify, al facturar varios pedidos en uno solo (BLK de "Bulk")</p>
                </li>
                <li>
                    <p><b>ECOM:</b> Factura generada por tu cliente en Checkout o por tu página de facturación (ECOM de eCommerce)</p>
                </li>
                <li>
                    <p>
                        <b>REF:</b> Nota de crédito asociada a algún pedido, creada cuando se extrae un pedido de una factura global automáticamente, al facturar el reeombolso de un pedido previamente facturado. (REF de Refunded)
                        <br /><span className="small quote"> Si quieres saber que pasa cuando un cliente obtiene una factura que está incluída en una factura global, entra <Link to="../facturacion-global">aquí</Link></span>
                    </p>
                </li>
                <li>
                    <p><b>GB:</b> Factura global, ya sea generada automáticamente o manualmente (GB de Global)</p>
                </li>
            </ul>
            <ImageFooted explanation={`Sección de Mis Facturas, aquí podrás revisar, descargar o cancelar facturas.`}>
                <IMG_Shopify file={'onBills'} />
            </ImageFooted>
            <SectionNavigate previous={`/ecommerce/shopify/facturacion-en-checkout`} next={`/ecommerce/shopify/facturacion-sencilla`}/>
        </div>
    </Layout>
)

export default ShopifyPostFacturacion;